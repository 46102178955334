import { useState, useEffect } from 'react';

import { ItemFinca } from '../../componentes/ItemFinca';
import { Boton } from '../../componentes/Boton';

import '../../App.css';
import '../../estilos/FormPropietario.css';

export const ListarFinca = ({ listafincas, getFincas }) => {

    const [showEliminar, setShowEliminar] = useState(false)
    const [message, setMessage] = useState(false)

    useEffect(() => {
        getFincas()
    }, [])

    return (
        <>
            <div className='container' >
                <div className='col-xs-12'>
                    {
                        showEliminar && (
                            <div className="alert alert-warning alert-dismissible fade show mt-3" role="alert">
                                <strong>{message}</strong>
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowEliminar(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        )
                    }

                    {listafincas?.length > 0 && (
                        <table className="table table-light mt-4">
                            <thead>
                                <tr style={encabezadoCss}>
                                    <th scope="col" className='col-1'>#</th>
                                    <th scope="col" className='col-4'>Nombre</th>
                                    <th scope="col" className='col-5'>Dirección</th>
                                    <th scope='col' className='col-2'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listafincas?.length > 0 && listafincas?.map((finca, contador) => (
                                        <ItemFinca key={finca.id} {...finca} contador={contador} finca={finca} setShowEliminar={setShowEliminar} setMessage={setMessage} getFincas={getFincas} />
                                    ))
                                }

                            </tbody>

                        </table>
                    )
                    }

                </div>

                <div className='col-xs-6'>
                    {
                        listafincas.length == [] && (
                            <div className="alert alert-warning alert-dismissible fade show mt-3 text-center" role="alert">
                                <strong>Agregar fincas</strong>
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowEliminar(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        )
                    }
                </div>

                <div className='d-flex'>
                    <div className='col-6 d-flex justify-content-end'>
                        <Boton ruta={'home'} accion={'Regresar'} />
                    </div>
                    <div className='col-6'>
                        <Boton ruta={'finca'} accion={'Agregar'} />
                    </div>

                </div>

            </div>

        </>
    );
}

const encabezadoCss = {
    background: '#1F618D',
    color: 'white'
}

