import { useState, useRef, useEffect } from 'react';

import '../estilos/SubirImg.css';


export const SubirImg = ({ imagenFinca, setImagenFinca,defaultImg }) => {

  const [imagen, setImagen] = useState()
  const fileInputRef = useRef();

  const imageHandler = (e) => {
    const file = e.target.files[0]

    if (file && file.type.substr(0, 5) === 'image') {
      setImagen(file)
    } else {
      setImagen(defaultImg)
    }
  }

  const aniadirImagen = (e) => {
    e.preventDefault()
    fileInputRef.current.click()

  }

  useEffect(() => {
    const reader = new FileReader()
    if (imagen) {
      reader.onloadend = async () => {
       /*  const resizedImage = await resizeImage(reader.result, 90, 60);
        setImagenFinca(resizedImage) */
        setImagenFinca(reader.result)
      }
      reader.readAsDataURL(imagen)
    } else {
      setImagenFinca(defaultImg) 
    }
  }, [imagen])

  return (
 
      <div className="contenedor-img d-flex justify-content-center">
        <div className="contenedor-subirimg">
          <div className="contenedor-mensaje-subirimg m-0">
            <h1 className="mensaje-subirimg"> Foto de la Finca </h1>
          </div>
          <div className="img-holder mt-0">
            <img src={imagenFinca} alt="Imagen finca" id="img" className="img img-fluid"/>
          </div>
          <input type="file" ref={fileInputRef} onChange={imageHandler} name="img-upload" id="input" accept="image/*" />
          <button onClick={aniadirImagen} className="mt-1 p-1 btn btn-success">
            Añadir imagen
          </button>
        </div>
      </div>
   

  )
}


