import { useContext, useState } from 'react';
import Select from 'react-select'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es'

import ListaSecciones from "../../componentes/ListaSecciones";
import { getYears, meses } from '../../datos/datosSelectores';
import { KeyboardDatePicker } from '@material-ui/pickers';

import '../../App.css';
import { api } from '../../api/api';
import { AuthContext } from '../../context/AuthContext';
import { Boton } from '../../componentes/Boton';
import '../../estilos/FormPropietario.css'
import { formatearCalendario } from '../../datos/formatearCalendario';


export const Recibo = ({ listafincas, propietarios }) => {

    const [fincaSelect, setFincaSelect] = useState("");
    const [listaSecciones, setListaSecciones] = useState([]);
    const [mesSelect, setMesSelect] = useState()
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')
    const [listaIdPropiedades, setListaIdPropiedades] = useState([]);
    const [annioSelect, setAnnioSelect] = useState('')
    const [fechaEmision, setFechaEmision] = useState(new Date());
    const [fechaVencimiento, setFechaVencimiento] = useState(new Date())

    const { user } = useContext(AuthContext)

    const years = getYears()

    const getRecibo = async () => {
        setShowAlert(false)

        const data_POST = {
            "user": user.username,
            "id": fincaSelect,
            "mes": mesSelect,
            "year": annioSelect,
            
        }

        const res = await api.post(`recibos_listar_ultimo`, data_POST)
        let isArray = Array.isArray(res.data.lista);

        if (isArray) {
            setListaSecciones(res.data.lista)
            setShowAlert(false)

        } else {
            setListaSecciones([])
            setShowAlert(true)
            setMessage('No hay recibo del mes anterior')
        }
    };


    const guardarRecibo = async () => {
        const data = {
            "user": user.username,
            "Finca": fincaSelect,
            "Year": annioSelect,
            "Mes": mesSelect,
            "Seccion": listaSecciones,
            "fecha_emision": formatearCalendario(fechaEmision),
            "fecha_vencimiento": formatearCalendario(fechaVencimiento),
            /* tipo: tipoSelect, */
            "listaIdPropiedades": listaIdPropiedades
        }

        const res = await api.post(`recibos_crear`, data);

        if (res.data.status === 201) {
            setShowAlert(true)
            setMessage(res.data.mensaje)
        } 
        if (res.data.status === 400) {
            setShowAlert(true)
            setMessage(res.data.mensaje)
        } 
        
    }

    const getPropiedadesPorFinca = (finca_id) => {
        const propiedades = propietarios?.filter(p => p.finca_id === finca_id)
        const listaidpropiedades = propiedades?.map(pro => pro.idpropiedad)
        setListaIdPropiedades(listaidpropiedades)
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-3 mt-4'>
                        <div className='d-flex justify-content-center'>
                            <Boton ruta={'recibos'} accion={'Regresar'} />
                        </div>
                    </div>

                    <div className='col-6'>

                        <form className='d-flex py-2 justify-content-around'>

                            <div className='justify-content-center'>
                                <h2 className='h2-propietario'>Finca: </h2>
                                <div className='input-select mb-4 text-center'>
                                    <Select
                                        onChange={
                                            (finca_seleccion) => {
                                                setFincaSelect(finca_seleccion.value)
                                                getPropiedadesPorFinca(finca_seleccion.value)
                                            }
                                        }
                                        options={listafincas?.map(sup => ({ label: sup.nombre, value: sup.id }))}
                                    />
                                </div>
                            </div>

                            <div className='justify-content-center'>
                                <h2 className='h2-propietario'>Mes: </h2>
                                <div className='input-select-small mb-4 text-center'>
                                    <Select
                                        onChange={
                                            (mes_seleccion) => {
                                                setMesSelect(mes_seleccion.value)
                                            }
                                        }
                                        options={meses?.map(m => ({ label: m.mes, value: m.id }))}
                                    />
                                </div>
                            </div>
                            <div className='justify-content-center'>
                                <h2 className='h2-propietario'>Año: </h2>
                                <div className='input-select-small mb-4 text-center'>
                                    <Select
                                        onChange={
                                            (tipo_seleccion) => {

                                                setAnnioSelect(tipo_seleccion.value)
                                            }
                                        }
                                        options={years?.map(year => ({ label: year, value: year }))}
                                    />
                                </div>
                            </div>


                            {/*  <div className='justify-content-center'>
                                <h2 className='h2-propietario'>Tipo: </h2>
                                <div className='input-select mb-4 text-center'>
                                    <Select
                                        onChange={
                                            (tipo_seleccion) => {
                                             
                                                setTipoSelect(tipo_seleccion.value)
                                                getRecibo(tipo_seleccion.value)
                                            }
                                        }
                                        options={tipos?.map(sup => ({ label: sup.tipo, value: sup.id }))}
                                    />
                                </div>
                            </div> */}

                        </form>
                        <form className='d-flex justify-content-between pt-0'>
                            <div>
                                <h2 className='h2-propietario'>Fecha de emisión </h2>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                    <KeyboardDatePicker
                                        value={fechaEmision}
                                        onChange={setFechaEmision}
                                        format="dd/MM/yyyy"
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        invalidDateMessage="Fecha inválida"
                                    />
                                </MuiPickersUtilsProvider>

                            </div>
                            <div>
                                <h2 className='h2-propietario'>Fecha de vencimiento </h2>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                    <KeyboardDatePicker
                                        value={fechaVencimiento}
                                        onChange={setFechaVencimiento}
                                        format="dd/MM/yyyy"
                                        invalidLabel="Fecha inválida"
                                        inputProps={{
                                            style: { textAlign: 'center' }
                                        }}
                                        invalidDateMessage="Fecha inválida" 
                                        minDate={fechaEmision}
                                        minDateMessage="Seleccionar una fecha mayor a la fecha de emisión"
                                        />
                                </MuiPickersUtilsProvider>

                            </div>

                        </form>

                    </div>

                    <div className='col-3 mt-2'>
                        <div className='d-flex justify-content-center align-items-center mt-4 '>
                            <button type="button" className="btn btn-primary boton" onClick={() => getRecibo()}>
                                Buscar
                            </button>
                        </div>
                    </div>

                </div>

                <div className='d-flex justify-content-center align-items-center'>
                    {
                        showAlert && (
                            <div className="alert alert-success alert-dismissible fade show mt-4" role="alert">
                                <strong>{message}</strong>
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        )
                    }
                </div>

                <div className='container-fluid'>
                    <ListaSecciones listaSecciones={listaSecciones} setListaSecciones={setListaSecciones} />
                    <div className='contenedor-btn-guardar'>
                        <button onClick={guardarRecibo} className='btn-guardar'>Guardar</button>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Recibo;