
import { Link } from 'react-router-dom';
import "../estilos/estilosTablas.css"
import { useContext, useEffect} from 'react'
import { AuthContext } from '../context/AuthContext';
import "../../src/estilos/estilosTablas.css"
import Swal from 'sweetalert2';
import { api } from '../api/api';


export const ItemPropietario = ({ pro, listafincas, propietariosPorFinca, setPropietariosPorFinca, contador, setMessage, setShowAlert,calcular_total_participacion }) => {

        const { idpropietarios, nombres_y_apellidos, numero_departamento, numero_estacionamiento, numero_deposito, idpropiedad, porcentaje_participacion } = pro;
        const { user } = useContext(AuthContext)
        const eliminarPropietario = (idpropietarios) => {

                Swal.fire({
                        title: '¿Estás seguro?',
                        text: `No podrás recuperar la propiedad de ${nombres_y_apellidos} una vez eliminada.`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Eliminar',
                        cancelButtonText: 'Cancelar',
                        confirmButtonColor: '#d33'
                    }).then((result) => {
                        if (result.value) {
                                const data_DELETE = {
                                        "user": user.username,
                                        "idpropietarios": idpropietarios,
                                        "idpropiedad":idpropiedad
                                }

                                try {
                                        api.put("propietario_borrar", data_DELETE).then(
                                                res => {
                
                                                        setMessage(res.data.mensaje)
                                                        setShowAlert(true)
                                                        actualizarTablaEliminada(idpropietarios)
                                                        calcular_total_participacion(pro.finca_id)
                
                                                }
                                        ) 
                
                                }
                                catch (error) {
                                        alert("Hubo error en el servidor")
                                }
                        }
                    });
              
        }

        const actualizarTablaEliminada = (id) => {
                const tablaActualizada = propietariosPorFinca?.filter(pro => pro.idpropietarios !== id)
                setPropietariosPorFinca(tablaActualizada)
        }


        return (

                <tr className="tabla-style">
                        <th scope="row">{contador + 1}</th>
                        <td>{nombres_y_apellidos}</td>
                        <td>{numero_departamento}</td>
                        <td>{numero_estacionamiento}</td>
                        <td>{numero_deposito}</td>
                        <td>{porcentaje_participacion}</td>
                        <td className="d-flex align-items-center justify-content-around">

                                <Link
                                        to={`/videofinca/propiedades/${idpropiedad}`}
                                        state={{ pro, listafincas }}

                                >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-pencil-square text-primary" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg>
                                </Link>
                                <svg onClick={() => eliminarPropietario(idpropietarios)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash text-danger svg" viewBox="0 0 16 16">

                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />

                                </svg>
                            
                        </td>


                </tr >



        )
}
