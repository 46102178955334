
import '../estilos/Seccion.css';
import { BsFillTrashFill } from "react-icons/bs";
import { ListaSubSecciones } from './ListaSubSecciones';

function Seccion({ idseccion, descripcion, listaSecciones, setListaSecciones, seccion, visualizarReciboSeccion = false, visualizarReciboSubseccion }) {

    const { subsecciones } = seccion;
 
    const eliminarSeccion = descripcion => {
        const seccionesActualizadas = listaSecciones.filter(sec=> sec.descripcion !== descripcion);
        setListaSecciones(seccionesActualizadas);
    }

    return (
        <div className='form-info'>
           <h3> {descripcion.charAt(0).toUpperCase() + descripcion.slice(1)} </h3> 
            
            <ListaSubSecciones subsecciones={subsecciones} listaSecciones={listaSecciones} setListaSecciones={setListaSecciones} descripcion={descripcion} visualizarReciboSubseccion={visualizarReciboSubseccion} /> 
            <div className='seccion-contenedor-iconos'>
                {
                    !visualizarReciboSeccion && <BsFillTrashFill className="seccion-icono" onClick={() => eliminarSeccion(descripcion)} />

                }
            </div>
        </div>
    );
}

export default Seccion;