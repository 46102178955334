import { useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";
import "../../src/estilos/estilosTablas.css"
import { AuthContext } from "../context/AuthContext";
import "../estilos/estilosTablas.css";
import { api } from "../api/api";

export const ItemRecibo = ({ contador, idrecibos, finca_id, mes, year, listafincas, recibo, meses, years, tipos, setShowLoading, setMessage, setShowAlert }) => {

    const { user } = useContext(AuthContext)

    const [nombreFinca, setNombreFinca] = useState('');

    const encontrarNombreFinca = () => {
        const finca = listafincas.find(finca => finca.id === finca_id)
        setNombreFinca(finca.nombre)
    }

    const labelMes = meses.find(m => m.id === mes).mes
    const labelYear = years.find(y => y === year)

    useEffect(() => {
        encontrarNombreFinca()
    }, [])

    const generarRecibo = async (id) => {

        setShowLoading(true)
        setShowAlert(false)
        const data_POST = {
            'user': user.username,
            'idrecibo': id,
            'id_finca': finca_id,

            'mes': mes,
            'year': year,
            'id_recibo': idrecibos
        }

        try {
            const res = await api.post(`recibos_generar`, data_POST);

            if (res.data?.status === 200) {
                setShowLoading(false)
                setMessage(res.data?.mensaje)
                setShowAlert(true)
            }
            if (res.data?.status === 400) {
                setShowLoading(false)
                setMessage(res.data?.mensaje)
                setShowAlert(true)
            }
            if (res.status === 500) {
                setShowLoading(false)
                setMessage('Error en el servidor')
                setShowAlert(true)
            }

        } catch (error) {
            setShowLoading(false)
            setMessage('Error en el servidor')
            setShowAlert(true)
        }

    }

    return (
        <tr className="tabla-style">
            <th scope="row">{contador + 1}</th>
            <td>{nombreFinca}</td>
            <td>{labelMes}</td>
            <td>{labelYear}</td>

            <td className="d-flex align-items-center justify-content-around">
                <Link
                    to={`/videofinca/recibo/${idrecibos}`}
                    state={{ recibo, listafincas }}

                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-eye-fill text-success" viewBox="0 0 16 16">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                </Link>
                {/* <Link
                    to={`/Videofincas/recibo-actualizar/${idrecibos}`}
                    state={{ recibo, listafincas}}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-pencil-square text-primary" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>

                </Link> */}
                <svg onClick={() => generarRecibo(idrecibos)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-file-earmark-pdf-fill svg text-danger" viewBox="0 0 16 16">
                    <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                    <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                </svg>

            </td>


        </tr >
    )
}
