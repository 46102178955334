import { useNavigate } from "react-router-dom";
import "../estilos/Boton.css"
export const Boton = ({ruta,accion, listafincas, propietariosPorFinca, recibosPorFinca, propietarios}) => {

    const navigate = useNavigate();
    const linkear = e => {
        e.preventDefault();                               
        navigate(`/videofinca/${ruta}`,{
            replace: true,
            state: { listafincas: listafincas || [], propietariosPorFinca: propietariosPorFinca || [], recibosPorFinca: recibosPorFinca || [], propietarios: propietarios }
        });
    };

    return (
     
            <button type="button" className="btn btn-primary boton mt-2" onClick={linkear}>
                {accion}
            </button>

    );
}
