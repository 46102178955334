import { useState, useEffect, useContext } from 'react'

import { AuthContext } from '../../context/AuthContext';

import ReactSelect from 'react-select';
import { ItemPropietario } from '../../componentes/ItemPropietario';

import '../../App.css';
import '../../estilos/FormPropietario.css'
import { Boton } from '../../componentes/Boton';
import { api } from '../../api/api';

export const ListarPropietario = ({ listafincas, propietarios, getPropietarios}) => {
   
    const [propietariosPorFinca, setPropietariosPorFinca] = useState([])
    const [mensaje, setMensaje] = useState('Buscar finca')
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')
    const [totalPorcentaje, setTotalPorcentaje] = useState()
    const [showAlertParticipacion, setShowAlertParticipacion] = useState(false)
    const { user } = useContext(AuthContext)

    const buscarPropietarioPorFinca = (id) => {
        const propietariosEncontrado = propietarios?.filter(propietario => propietario.finca_id === id)
        setPropietariosPorFinca(propietariosEncontrado)
        setMensaje('No hay Propietarios')
        setShowAlertParticipacion(true)
    }

    const calcular_total_participacion = async (id) => {
        const data_POST = {
            "user": user.username,
            "finca_id": id,
        }

        const resp = await api.post("finca_total_participacion", data_POST)

        if (resp.status === 200) {
 
            setTotalPorcentaje(resp.data.mensaje)
        } 
    }

    useEffect(() => {
        getPropietarios()
    }, [])
    
 
    return (
        <div>
            <div className='container-fluid' >
                <div className='row'>


                    <div className='col-xs-12 col-sm-12'>

                        <form className='d-flex justify-content-center mt-3'>
                            <h2 className='h2-propietario'> Finca: </h2>
                            <div className='input-select mb-3'>
                                <ReactSelect
                                    onChange={
                                        (seleccion) => {

                                            buscarPropietarioPorFinca(seleccion.value)
                                            /* total_porcentaje_participacion(seleccion.value) */
                                            calcular_total_participacion(seleccion.value)
                                        }
                                    }
                                    options={listafincas?.map(sup => ({ label: sup.nombre, value: sup.id }))}
                                />
                            </div>


                        </form>
                    </div>


                </div>

            </div>

            <div className='container-fluid ' >
                {
                    showAlertParticipacion && (
                        <div className='container-fluid row justify-content-end  m-0'>
                            <div className="col-4 alert alert-success text-center mb-0" role="alert">
                                Total: {totalPorcentaje}% de participacion
                            </div>
                        </div>
                    )
                }


                <div className='col-12 pb-2 text-center'>
                    {
                        showAlert && (
                            
                                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                    <strong>{message}</strong>
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                           


                        )
                    }

                    {propietariosPorFinca.length !== 0 && (
                        <table className="table table-light mt-4">
                            <thead>
                                <tr style={encabezadoCss}>
                                    <th scope="col" className='col-1'>#</th>
                                    <th scope="col" className='col-4'>Propietario</th>
                                    <th scope="col" className='col-1'>N° Departamento</th>
                                    <th scope="col" className='col-1'>N° Estacionamiento</th>
                                    <th scope="col" className='col-1'>N° de Depósito</th>
                                    <th scope='col' className='col-2'>% de Participaciones</th>
                                    <th scope='col' className='col-2'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {propietariosPorFinca?.map((pro, contador) => (
                                    <ItemPropietario key={pro.idpropiedad} {...pro} contador={contador} pro={pro} listafincas={listafincas} propietariosPorFinca={propietariosPorFinca} setPropietariosPorFinca={setPropietariosPorFinca} getPropietarios={getPropietarios} setShowAlert={setShowAlert} setMessage={setMessage} calcular_total_participacion={calcular_total_participacion}/>
                                ))
                                }

                            </tbody>

                        </table>
                    )

                    }


                </div>
                <div className='d-flex'>
                    <div className='col-6 d-flex justify-content-end'>
                        <Boton ruta={'home'} accion={'Regresar'} />
                    </div>
                    <div className='col-6'>
                        <Boton ruta={'propiedad'} accion={'Agregar'} listafincas={listafincas} propietariosPorFinca={propietariosPorFinca} />
                    </div>

                </div>

            </div>

           {/*  <div className='d-flex justify-content-center align-items-center mb-2'>
                

                <Regresar
                    ruta='home' />
                <div className='ml-4 mt-2'>
                    <Link to="/Videofincas/propiedad"
                        state={{ listafincas: listafincas, propietariosPorFinca: propietariosPorFinca }}>
                        <button type="button" className="btn btn-guardar">
                            Agregar
                        </button>
                    </Link>
                </div>
            </div> */}


        </div>

    )
}

const encabezadoCss = {
    background: '#1F618D',
    color: 'white'
}
