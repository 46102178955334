import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

import Select from 'react-select';
import { Boton } from '../../componentes/Boton';

import { doc } from '../../datos/datosSelectores';

import '../../App.css';
import '../../estilos/FormPropietario.css';
import '../../estilos/TipoDoc.css';
import "../../estilos/estilosTablas.css"
import { api } from '../../api/api';


export const Propietario = () => {

    const location = useLocation();
    const { user } = useContext(AuthContext)

    const [nombres, setNombres] = useState("")
    const [listatdoc, setListatdoc] = useState(doc)
    const [tdocSelect, setTdocSelect] = useState("")
    const [ndoc, setNdoc] = useState("")
    const [correo, setCorreo] = useState("")
    const [ncel, setNcel] = useState("")
    const [dep, setDep] = useState("")
    const [estacionamiento, setEstacionamiento] = useState("")
    const [deposito, setDeposito] = useState("")
    const [part, setPart] = useState(0)
    const [fincaSelect, setFincaSelect] = useState("")
    const [n_cuenta, setN_cuenta] = useState("")
    const [cci, setCci] = useState("")
    const [titular, setTitular] = useState("")

    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')
    const [error, setError] = useState('warning')
    const [inputError, setInputError] = useState(false)
    const [casosInputError, setCasosInputError] = useState('')
    const [mensajeVerificarDni, setMensajeVerificarDni] = useState('')

    function handlesubmit(e) {
        e.preventDefault()
    }

    function enviarPropietario() {

        const data_POST = {
            "user": user.username,
            "id_finca": fincaSelect,
            "nombres_y_apellidos": nombres?.trim(),
            "tipodocumento": tdocSelect?.trim(),
            "nro_documento": ndoc?.trim(),
            "correo": correo?.trim(),
            "telefono": ncel?.trim(),
            "numero_deposito": deposito?.trim(),
            "numero_departamento": dep?.trim(),
            "numero_estacionamiento": estacionamiento?.trim(),
            "part": part?.trim(),
            "num_cuenta": n_cuenta?.trim(),
            "cci": cci?.trim(),
            "cuenta_titular": titular?.trim(),
        }

        try {
            api.post("propietario", data_POST).then(
                res => {

                    switch (res.data.input_error) {
                        case 'departamento':
                            setInputError(res.data.error)
                            setCasosInputError('departamento')
                            break;
                        case 'estacionamiento':
                            setInputError(res.data.error)
                            setCasosInputError('estacionamiento')
                            break;
                        case 'deposito':
                            setInputError(res.data.error)
                            setCasosInputError('deposito')
                            break;
                        case 'departamento y deposito':
                            setInputError(res.data.error)
                            setCasosInputError('departamento y deposito')
                            break;
                        case 'estacionamiento y departameto':
                            setInputError(res.data.error)
                            setCasosInputError('estacionamiento y departameto')
                            break;
                        case 'estacionamiento y deposito':
                            setInputError(res.data.error)
                            setCasosInputError('estacionamiento y deposito')
                            break;
                        case 'todos':
                            setInputError(res.data.error)
                            setCasosInputError('todos')
                            break;
                        default:
                            break;
                    }
                    if (res.data.status === 200) {
                        setMessage(res.data.mensaje)
                        setShowAlert(true)
                        setError('primary')
                        setNombres("")
                        setTdocSelect("")
                        setNdoc("")
                        setCorreo("")
                        setNcel("")
                        setFincaSelect("")
                        setDep('')
                        setEstacionamiento('')
                        setDeposito('')
                        setPart(0)
                    }
                    if (res.data.status === 201) {
                        setMessage(res.data.mensaje)
                        setShowAlert(true)
                        setError('warning')
                    }
                    if (res.data.status === 400) {
                        setMessage(res.data.mensaje)
                        setShowAlert(true)
                        setError('warning')
                    }
                    if (res.data.status === 500) {
                        setMessage(res.data.mensaje)
                        setShowAlert(true)
                        setError('warning')
                    }
                }
            )
        }
        catch (error) {
            alert("Hubo error en el servidor")
        }

    }

    const verificarDni = (nro_dni) => {
        const data_POST = {
            "user": user.username,
            "nro_documento": nro_dni,
        }

        api.post("buscar_propietario", data_POST).then(
            res => {
                try {
                    if (res.status === 200) {
                        setMensajeVerificarDni('')
                        setNombres(res.data[0].nombres_y_apellidos)
                        setCorreo(res.data[0].correo)
                        setNcel(res.data[0].telefono)
                        setN_cuenta(res.data[0].num_cuenta)
                        setCci(res.data[0].cci)
                        setTitular(res.data[0].cuenta_titular)
                    }
                    if (res.data?.status === 400) {
                        setMensajeVerificarDni('No se encontró información del propietario')
                    }
                } catch (e) {
                    setMensajeVerificarDni('No se encontró información del propietario')
                    setNombres('')
                    setCorreo('')
                    setNcel('')
                    setN_cuenta('')
                    setCci('')
                    setTitular('')
                }

            }
        )
    }

    return (
        <>
            <div className='container-fluid' >
                <div className='row'>
                    <div className='col-3 mt-4'>
                        <div className='d-flex justify-content-center'>
                            <Boton ruta={'propiedades'} accion={'Regresar'} />
                        </div>
                    </div>

                    <div className='col-9'>
                        <form className="form-propietarios pb-0" onSubmit={handlesubmit}>
                            <div className="form-group row">
                                {
                                    showAlert && (
                                        <div className={`alert alert-${error} alert-dismissible fade show`} role="alert">
                                            <strong>{message}</strong>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )
                                }

                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="exampleFormControlSelect1" className="col-3 col-form-label">Finca:</label>
                                <div className='input-select col-4'>
                                    <Select
                                        onChange={
                                            (seleccion) => {
                                                setFincaSelect(seleccion.value)
                                            }
                                        }
                                        options={location.state.listafincas?.map(sup => ({ label: sup.nombre, value: sup.id }))}
                                    /></div>

                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Departamento :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    placeholder="Departamento "
                                    autoComplete="off"
                                    name={"dep"}
                                    value={dep}
                                    onChange={e => e.target.value === "" ? setDep(null) : setDep(e.target.value)}
                                    style={
                                        (inputError & (casosInputError === 'departamento' || casosInputError === 'departamento y deposito' || casosInputError === 'estacionamiento y departameto' || casosInputError === 'todos')) ? {
                                            borderColor: 'red'
                                        } : {}
                                    } />

                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Estacionamiento:</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Estacionamiento "
                                    name={"estacionamiento"}
                                    value={estacionamiento}
                                    onChange={e => e.target.value === "" ? setEstacionamiento(null) : setEstacionamiento(e.target.value)}
                                    style={
                                        (inputError & (casosInputError === 'estacionamiento' || casosInputError === 'estacionamiento y departameto' || casosInputError === 'estacionamiento y deposito' || casosInputError === 'todos')) ? {
                                            borderColor: 'red'
                                        } : {}
                                    } />
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Nro. de Depósito:</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Depósito "
                                    name={"Numero_deposito"}
                                    value={deposito}
                                    onChange={e => e.target.value === "" ? setDeposito(null) : setDeposito(e.target.value)}
                                    style={
                                        (inputError & (casosInputError === 'deposito' || casosInputError === 'departamento y deposito' || casosInputError === 'estacionamiento y deposito' || casosInputError === 'todos')) ? {
                                            borderColor: 'red'
                                        } : {}
                                    } />
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Participación (%):</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Participación(%) "
                                    name={"part"}
                                    value={part}
                                    onChange={e => setPart(e.target.value)} />
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="exampleFormControlSelect1" className="col-3 col-form-label">Tipo:</label>
                                <div className='input-select col-4'>
                                    <Select
                                        onChange={
                                            (seleccion) => {
                                                setTdocSelect(seleccion.value)
                                            }
                                        }
                                        options={
                                            listatdoc.map(sup => ({ label: sup.name, value: sup.id })
                                            )
                                        }

                                    />
                                </div>
                            </div>


                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Nro. de Documento :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Nro. de Documento "
                                    name={"ndoc"}
                                    value={ndoc}
                                    onChange={e => setNdoc(e.target.value)} />
                                <svg onClick={() => verificarDni(ndoc)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search text-success svg ml-2 font-weight-bold" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                                <span> {mensajeVerificarDni} </span>
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Nombres y Apellidos:</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Nombre y Apellido "
                                    name={"nombres"}
                                    value={nombres}
                                    onChange={e => {
                                        setNombres(e.target.value)
                                    }
                                    } />
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Correo Electrónico:</label>
                                <input type='email'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Correo Electrónico "
                                    name={"correo"}
                                    value={correo}
                                    onChange={e => setCorreo(e.target.value)} />
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Nro. de Celular :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Nro. de Celular "
                                    name={"ncel"}
                                    value={ncel}
                                    onChange={e => setNcel(e.target.value)} />
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">N° Cuenta :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="N° Cuenta"
                                    name={"n_cuenta"}
                                    value={n_cuenta}
                                    onChange={e => setN_cuenta(e.target.value)} />
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">CCI :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="CCI"
                                    name={"cci"}
                                    value={cci}
                                    onChange={e => setCci(e.target.value)} />
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Titular :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Titular"
                                    name={"titular"}
                                    value={titular}
                                    onChange={e => setTitular(e.target.value)} />
                            </div>

                        </form>
                        <div className='contenedor-btn-guardar' onClick={() => enviarPropietario()}>
                            <button className='btn-guardar'>GUARDAR</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
