import React, { useContext } from 'react'
import '../styles-navbar.css'
import videoFincaLogo from '../../imagenes/logo_videofinca.png'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

export const NavBar = () => {

  const location = useLocation();
  const { user, logout } = useContext(AuthContext)
  let name = location.pathname.split('/')[2]
  const navigate = useNavigate()

  const usuario = user?.username || ""

  const onLogout = () => {
    logout()
    navigate('/videofinca', {
      replace: true
    })
  }

  switch (name) {
    case 'home':
      name = 'Menú principal'
      break;
    case 'recibo':
      name = 'Generar Recibo';
      break;
    case 'recibos':
      name = 'Recibos';
      break;
    case 'propiedad':
      name = 'Propiedades'
      break;
    case 'propiedades':
      name = 'Propiedades'
      break;
    case 'finca':
      name = 'Fincas'
      break;
    case 'fincas':
      name = 'Fincas'
      break;
    case 'descarga':
      name = 'Descargas'
      break;
    case 'registro_admin':
      name = 'Administradores'
    default:
      break;
  }
  return (
    <div className='contenedor-cabecera row align-items-center'>

        <Link className='col-4 d-flex justify-content-center' to='/videofinca/home'>
          <img
            src={videoFincaLogo}
            className='videofincas-logo'
            alt='Logo Videofinca'
          />
        </Link>


        <div className='col-4'>

          <h1 className='m-0 text-center titulo-principal '>
            {name}
          </h1>
        </div>

        <div className='col-2 d-flex align-items-center justify-content-around'>
          {
            user?.username && (
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person-square" viewBox="0 0 16 16" style={{ color: "#1F8D27", minHeight: "2rem", minWidth:"2rem" }}>
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z" />
              </svg>
            )
          }

          <h4 className='m-0' style={{ color: "#AEB6BF" }}>
            { name != 'registro_admin' && usuario.toUpperCase()}
          </h4>
        </div>
        <div className='col-2 d-flex justify-content-center'>
          <Link className='main_div py-3'>
            <button onClick={onLogout}>Salir</button>
          </Link>
        </div>
      </div>


  )
}