import { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate, Router } from "react-router-dom";

import { PublicRoute, PrivateRoute } from "./";
import { AuthContext } from "../context/AuthContext";

//Pages
import { Finca, ListarFinca, UpdateFinca } from '../pages/Finca'
import { Propietario, ListarPropietario, UpdatePropietario } from "../pages/Propietario";
import { Recibo, ListarRecibo, VisualizarRecibo } from '../pages/Recibo';
import { Login } from "../pages/Auth";
import { Registro } from "../pages/Admin";
import { Menu } from "../pages";
import { api } from "../api/api";
import { getUrlServer } from "../api/GetUrlServer";


export const AppRouter = () => {

  const [listafincas, setListafincas] = useState([])
  const [recibos, setRecibos] = useState([])
  const [propietarios, setPropietarios] = useState([])
  const { user } = useContext(AuthContext)

  const getFincas = async () => {
    try {
      const data_POST = {
        user: user?.username
      }
      const res = await api.post('finca', data_POST)

      if (res.data.status === 200) {
        setListafincas(res.data.lista);
      } 
      if(res.data.status === 500){
        setListafincas([]);
      }
    } catch (e) {
      setListafincas([]);
      
    }


  }; 

  const getRecibos = async () => {

    try {
      const data_POST = {
        user: user?.username
      }
      const res = await api.post('recibos', data_POST)

      if (res.status === 200) {
        setRecibos(res.data)
      } else {
        setRecibos([])
      }

    } catch (e) {
      setRecibos([])
    }

  }

  const getPropietarios = async () => {

    try {
      const data_POST = {
        user: user.username
      }

      const res = await api.post('listar_propietarios', data_POST)
  
      if (res.data.status === 200) {
        setPropietarios(res.data.lista);
      } else {
        setPropietarios([]);
      }

    } catch (e) {
      setPropietarios([])
    }

  };

  useEffect(() => {
    if (user) {
      getFincas();
      getPropietarios();
      getRecibos();
    }

  }, []);

  return (
    <Routes>
   
      <Route path='/videofinca/' element={
        <PublicRoute>
          <Login />
        </PublicRoute>} />
      {/* <Route path='/videofinca/registro_admin' element={<PublicRoute><Registro /></PublicRoute>} /> */}
      <Route path='/*' element={
        <PrivateRoute>
          <Routes>
            <Route path='/videofinca/registro_admin' element={<Registro />} />
            <Route path='/videofinca/home' element={<Menu />} />
            <Route path='/videofinca/recibos' element={<ListarRecibo listafincas={listafincas} getRecibos={getRecibos} recibos={recibos} propietarios={propietarios} />} />
            <Route path='/videofinca/recibo' element={<Recibo listafincas={listafincas} propietarios={propietarios} />} />
            <Route path='/videofinca/recibo/:id' element={<VisualizarRecibo recibos={recibos} />} />
            <Route path='/videofinca/propiedades' element={<ListarPropietario listafincas={listafincas} propietarios={propietarios} getFincas={getFincas} getPropietarios={getPropietarios} />} />
            <Route path='/videofinca/propiedades/:id' element={<UpdatePropietario />} />
            <Route path='/videofinca/propiedad' element={<Propietario/>} />
            <Route path='/videofinca/fincas' element={<ListarFinca listafincas={listafincas} getFincas={getFincas} setListafincas={setListafincas} />} />
            <Route path='/videofinca/fincas/:id' element={<UpdateFinca />} />
            <Route path='/videofinca/finca' element={<Finca listafincas={listafincas} getFincas={getFincas} />} />

            
          </Routes>
        </PrivateRoute>}
      />
      
    </Routes>
  )
}
