
import FormSeccion from './FormSeccion';
import Seccion from './Seccion';


function ListaSecciones({listaSecciones,setListaSecciones}) {

    const agregarSeccion = seccion => {
        if (seccion.descripcion?.trim()) {
            seccion.descripcion = seccion.descripcion?.trim();
            const seccionesActualizadas = [...listaSecciones, seccion];
            setListaSecciones(seccionesActualizadas);
        }
    }
    
    return(
        <>  
            <div className='container-fluid'>
             
            <FormSeccion agregarSeccion={agregarSeccion}/>
            
                
            <div className='contenedor-secciones'>
               {
                    listaSecciones?.map((seccion,id) =>                    
                    <Seccion  
                        key={id}
                        {...seccion}
                        seccion={seccion}
                        listaSecciones={listaSecciones}
                        setListaSecciones={setListaSecciones}
                    />
                    )
                }
            </div>
            </div>
            
        </>
    );
}

export default ListaSecciones;