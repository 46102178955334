import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import Seccion from "../../componentes/Seccion"

import { meses } from "../../datos/datosSelectores";
import { Boton } from "../../componentes/Boton";
import { api } from "../../api/api";
import { AuthContext } from "../../context/AuthContext";

export const VisualizarRecibo = () => {

    const { user } = useContext(AuthContext)

    const location = useLocation();
    const reciboRecuperado = location.state.recibo;
    const listaFincas = location.state.listafincas;

    const [fincaRecuperado, setFincaRecuperado] = useState(reciboRecuperado.finca_id)
    const [mesRecuperado, setMesRecuperado] = useState(reciboRecuperado.mes)
    const [annioRecuperado, setAnnioRecuperado] = useState(reciboRecuperado.year)
    const [listaSeccionesVisualizar, setListaSeccionesVisualizar] = useState([])

    const obtenerLabelDeLaFincaSeleccionada = listaFincas.find(f => f.id === fincaRecuperado).nombre
    const obtenerLabelMes = meses.find(m => m.id === mesRecuperado).mes

    const visualizarRecibo = async () => {

        const data_POST = {
            'user': user.username,
            'id_finca': reciboRecuperado.finca_id,
            'mes': reciboRecuperado.mes,
            'year': reciboRecuperado.year,
            'id_recibo': reciboRecuperado.idrecibos
        }
        const res = await api.post(`recibos_visualizar`, data_POST);
        setListaSeccionesVisualizar(res.data)
    }

    useEffect(() => {
        visualizarRecibo()
    }, [])

    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className='col-3 mt-4 d-flex align-self-center justify-content-center'>
                        <Boton ruta={'recibos'} accion={'Regresar'} />
                    </div>
                    <div className="col-6">
                        <form className='form-propietarios d-flex'>
                            <div className='justify-content-center'>
                                <h2 className='h2-propietario'>Finca: </h2>
                                <div className='input-select mb-4 text-center'>
                                    
                                    <input className="form-control" type="text"
                                        name='obtenerLabelDeLaFincaSeleccionada'
                                        placeholder='Finca seleccionada' value={obtenerLabelDeLaFincaSeleccionada} disabled />
                                </div>
                            </div>
                            <div className='justify-content-center'>

                                <h2 className='h2-propietario'>Mes: </h2>
                                <div className='input-select mb-4 text-center'>
                                   
                                      <input className="form-control" type="text"
                                        name='obtenerLabelMes'
                                        placeholder='Mes seleccionada' value={obtenerLabelMes} disabled />
                                </div>
                            </div>
                            <div className='justify-content-center'>
                                <h2 className='h2-propietario'>Año: </h2>
                                <div className='input-select mb-4 text-center'>
                                    
                                    <input className="form-control" type="text"
                                        name='annioRecuperado'
                                        placeholder='Año' value={annioRecuperado} disabled />
                                </div>
                            </div>

                        </form>

                    </div>


                    <div className="container-fluid">
                        <div className='contenedor-secciones mt-2'>
                            {
                                listaSeccionesVisualizar?.map((seccion) =>
                                    <Seccion
                                        key={seccion.idseccion}
                                        {...seccion}
                                        seccion={seccion}
                                        listaSecciones={listaSeccionesVisualizar}
                                        visualizarReciboSeccion={true}
                                        visualizarReciboSubseccion={true}
                                    />
                                )
                            }
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
