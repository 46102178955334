import { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';

import { SubirImg } from '../../componentes/SubirImg';

import { useForm } from '../../hooks/useForm';
import { api } from '../../api/api';

import '../../App.css';
import '../../estilos/FormPropietario.css';
import { Boton } from '../../componentes/Boton';
import { resizeImage } from './resizeImage';

const defaultImg = 'https://images.vexels.com/media/users/3/157613/isolated/lists/9aa6a8344b89e45d1adbe578dd0fc2dd-icono-de-casa-de-edificio-alto.png'

export const Finca = () => {

    const { nombre, direccion, onInputChange, onResetForm } = useForm({
        nombre: '',
        direccion: '',
    })

    const [imagenFinca, setImagenFinca] = useState()

    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')
    const { user } = useContext(AuthContext)

    let resizeimg;

    const agregarImagen = async (e) => {
        e.preventDefault()

        try {

            if(imagenFinca != defaultImg){
                resizeimg = await resizeImage(imagenFinca,75,75);
                /* setImagenFinca(imagenFinca) */
            } else{
                resizeimg = ''
            }

            
            const data_POST = {
                "user": user.username,
                "Nombre": nombre?.trim(),
                "Direccion": direccion?.trim(),
                "imagen": resizeimg,  
            }

          const res = await api.post("crear_finca", data_POST)
  
            if (res.data.status === 201) {
                setMessage(res.data.mensaje)
                setShowAlert(true)
                onResetForm()
            }
            if (res.data.status === 500) {
                setMessage(res.data.mensaje)
                setShowAlert(true)
                onResetForm()
            }  
        } catch (error) {
            setMessage('Sucedió un error en el servidor al conseguir datos de la finca')
            setShowAlert(true)
            onResetForm()
        }
       

    }


    return (
        <>
            <div className='container-fluid' >
                <div className='row'>
                    <div className='col-3 mt-4'>
                        <div className='d-flex justify-content-center'>
                            <Boton ruta={'fincas'} accion={'Regresar'} />
                        </div>
                    </div>

                    <div className='col-6 vh-200 justify-content-center align-items-center'>

                        {
                            showAlert && (
                                <div className="alert alert-success alert-dismissible fade show mt-3 text-center" role="alert">
                                    <strong>{message}</strong>
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            )
                        }

                        <div className="form-group text-center mt-1" >
                            <label className="col col-form-label">Nombre de la Finca :</label>
                            <input type='text'
                                className='form-control col-8 '
                                id='subtitulo-finca'
                                autoComplete="off"
                                placeholder=""
                                name={"nombre"}
                                value={nombre}
                                onChange={onInputChange} />
                        </div>
                        <div className="form-group text-center" >
                            <label className="col col-form-label " id='textito'>Dirección de la Finca :</label>
                            <input type='text'
                                className='form-control col-8 '
                                id='subtitulo-finca'
                                autoComplete="off"
                                placeholder=""
                                name={"direccion"}
                                value={direccion}
                                onChange={onInputChange}
                            />
                        </div>
                        <SubirImg imagenFinca={imagenFinca} setImagenFinca={setImagenFinca} defaultImg={defaultImg}/>
                        <div className='contenedor-btn-guardar' onClick={agregarImagen}>
                            <button className='btn-guardar mt-2 mb-2'>GUARDAR</button>
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}
