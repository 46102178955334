import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext';

import '../../estilos/Login.css';
import '../../App.css';

const textLogin = {
    color: 'white'
}

export const Login = () => {

    const { login } = useContext(AuthContext);
   

    const navigate = useNavigate();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')

    const onLogin = async e => {
        e.preventDefault();
        const { validar, rol, mensaje } = await login(user, pwd)

       if (validar) {

            if(rol=='admin'){
                navigate("/videofinca/home");
            }
            if(rol=='super-admin'){
                navigate("/videofinca/registro_admin");
            }
            
        } else {
            
            setMessage(mensaje)
            setShowAlert(true)
        }  
    };



    return (
        <>
            <div id="contenedor-login">
              
            
                <div className='contenedor-seccion p-4  flex-column'>
                {
                    showAlert && (
                        <div className="alert alert-warning alert-dismissible fade show mt-3 text-center" role="alert">
                            <strong>{message}</strong>
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    )
                }
                    <div className='seccion'>
                
                   
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" className="bi bi-person-lock mx-auto" viewBox="0 0 16 16" style={{ color: "#AEB6BF" }}>
                            <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 5.996V14H3s-1 0-1-1 1-4 6-4c.564 0 1.077.038 1.544.107a4.524 4.524 0 0 0-.803.918A10.46 10.46 0 0 0 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h5ZM9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-2Zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1Z" />
                        </svg>
                        <form className="mt-4" id="form-login" onSubmit={onLogin}>
                            <label htmlFor="username" style={textLogin}>Usuario:</label>
                            <input
                                type="text"
                                id="username"
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                            />

                            <label htmlFor="password" style={textLogin}>Contraseña:</label>
                            <input
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                            />
                            <button id="btn-login" onClick={onLogin}>Ingresar</button>
                        </form>
                    </div>

                </div>
            </div>

        </>
    );
}
