export const resizeImage = (image, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      // Crea un canvas para dibujar la imagen redimensionada
      const canvas = document.createElement('canvas');
  
      // Carga la imagen
      const img = new Image();
      img.src = image;
      img.onload = function() {
        // Calcula la relación de aspecto de la imagen original
        const aspectRatio = img.width / img.height;
  
        // Establece las dimensiones del canvas en base a la relación de aspecto
        if (img.width > maxWidth) {
          canvas.width = maxWidth;
          canvas.height = maxWidth / aspectRatio;
        } else if (img.height > maxHeight) {
          canvas.height = maxHeight;
          canvas.width = maxHeight * aspectRatio;
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
        }
  
        // Dibuja la imagen en el canvas redimensionado
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
  
        // Devuelve la imagen como un objeto de URL
        resolve(canvas.toDataURL());
      };
    });
  };