
import { AppRouter } from "./router";
import { NavBar } from "./ui/components/NavBar";
import './App.css'
import { useEffect } from "react";
import { GetUrlServer } from "./api/GetUrlServer";

function App() {

const obtenerUrl = GetUrlServer()

let url_server = ''
obtenerUrl.then(function(url) {
  url_server = url
  localStorage.setItem('urlServer',url_server)
});


  return (
    <div className="pantalla">
      <NavBar />
      <AppRouter />
    </div>
  );
}

export default App;