import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

import { Boton } from '../../componentes/Boton';

import { useForm } from '../../hooks/useForm';

import '../../App.css';
import '../../estilos/FormPropietario.css';
import { api } from '../../api/api';
import { useRef } from 'react';
import { resizeImage } from './resizeImage';

const defaultImg = 'https://images.vexels.com/media/users/3/157613/isolated/lists/9aa6a8344b89e45d1adbe578dd0fc2dd-icono-de-casa-de-edificio-alto.png'

export const UpdateFinca = () => {

  const location = useLocation();
  const fincaRecuperado = location.state.finca
  const { id, nombre, direccion } = fincaRecuperado;

  let nombre_anterior = nombre;

  const { nombreInput, direccionInput, onInputChange, onResetForm } = useForm({
    nombreInput: nombre,
    direccionInput: direccion,
  })

  const { user } = useContext(AuthContext)
  const [showAlert, setShowAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [data, setData] = useState({})
  const [imagenFinca, setImagenFinca] = useState('')

  // useState de imagenFinca
  const [imagen, setImagen] = useState(imagenFinca)
  const fileInputRef = useRef();
  let resizeimg;

  const actualizarFinca = async () => {

    if (imagenFinca != defaultImg) {
      resizeimg = await resizeImage(imagenFinca, 75, 75);
    } else {
      resizeimg = ''
    }

    const data_PUT = {
      "user": user.username,
      "id": id,
      "nombre": nombreInput?.trim(),
      "direccion": direccionInput?.trim(),
      "nombre_anterior":nombre_anterior,
      "imagen": resizeimg

    }

    try {

      api.put("finca_actualizar", data_PUT).then(
        res => {
          setData(res.data)}
      )
    

    }
    catch (error) {
      setShowAlert(true)
      setMessage('Error al guardar imagen en el servidor')
    }
  }

  const buscar_finca = async () => {
    try {
      const data_POST = {
        "user": user.username,
        "finca_id": id,
      }

      const res = await api.post('obtener_finca', data_POST)

      if (res.data.status === 200) {
        setImagenFinca(res.data.imagen);

      } else {
        /* setImg(''); */
      }

    } catch (e) {
      /* setImg(''); */
    }
  }

  const imageHandler = (e) => {
    const file = e.target.files[0]
    /* setIdenImg(file.name) */
    if (file && file.type.substr(0, 5) === 'image') {
      setImagen(file)
    } else {
      setImagen(defaultImg)
    }
  }

  const aniadirImagen = (e) => {
    e.preventDefault()
    fileInputRef.current.click()

  }



  useEffect(() => {
    const reader = new FileReader()
    if (imagen) {
      reader.onloadend = async () => {
        /*  const resizedImage = await resizeImage(reader.result, 90, 60);
         setImagenFinca(resizedImage) */
        setImagenFinca(reader.result)
      }
      reader.readAsDataURL(imagen)
    } else {
      /* setImagenFinca(defaultImg)  */
    }
  }, [imagen])


  useEffect(() => {

    buscar_finca()
    if (data.status === 200) {
      setShowAlert(true)
      setMessage(data.mensaje)
      /* navigate(-1) */
    }

  }, [data])

  return (
    <>
      <div className='container-fluid' >
        <div className='row'>

          <div className='col-3 mt-4'>
            <div className='d-flex justify-content-center'>
              <Boton ruta={'fincas'} accion={'Regresar'} />
            </div>
          </div>


          <div className='col-6 vh-200 justify-content-center align-items-center'>
            <form action="" onSubmit={actualizarFinca}>
              <div className="form-group text-center mt-3" >
                {
                  showAlert && (
                    <div className={`alert alert-warning alert-dismissible fade show`} role="alert">
                      <strong>{message}</strong>
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )
                }
              </div>


              <div className="form-group text-center " >

                <label className="col col-form-label">Nombre de la Finca :</label>
                <input type='text'
                  className='form-control col-8 '
                  id='subtitulo-finca'
                  placeholder=""
                  name={"nombreInput"}
                  value={nombreInput}
                  onChange={onInputChange} />
              </div>
              <div className="form-group text-center" >
                <label className="col col-form-label " id='textito'>Direccion de la Finca :</label>
                <input type='text'
                  className='form-control col-8 '
                  id='subtitulo-finca'
                  placeholder=""
                  name={"direccionInput"}
                  value={direccionInput}
                  onChange={onInputChange}
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className='container-fluid' >
        {/* <SubirImg img={img} defaultImg={defaultImg}/>  */}
        {/*  Subir imagen */}
        <div className="contenedor-img d-flex justify-content-center">
          <div className="contenedor-subirimg">
            <div className="contenedor-mensaje-subirimg m-0">
              <h1 className="mensaje-subirimg"> Foto de la Finca </h1>
            </div>
            <div className="img-holder mt-0">
              <img src={imagenFinca} alt="Imagen finca" id="img" className="img img-fluid" />
            </div>
            <input type="file" ref={fileInputRef} onChange={imageHandler} name={imagenFinca} id="input" accept="image/*" />
            <button onClick={aniadirImagen} className="mt-1">
              Añadir imagen
            </button>
          </div>
        </div>
        <div className='contenedor-btn-guardar' onClick={() => actualizarFinca()}>
          <button className='btn-guardar'>Actualizar</button>
        </div>

      </div>
    </>
  )
}
