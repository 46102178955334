
import { Link } from "react-router-dom";
import '../App.css';

export const Menu = () => {

    return (
       
            <ul id="lista-rutas">
                <li id="elemento-lista-finca">
                    <Link id="link-finca" to="/videofinca/fincas">Fincas</Link>
                </li>
                <li id="elemento-lista-recibo">
                    <Link id="link-recibo" to="/videofinca/recibos">Recibos</Link>
                </li>
                <li id="elemento-lista-propietario">
                    <Link id="link-propietario" to="/videofinca/propiedades">Propiedades</Link>
                </li>
            </ul>
      
    );
}
