export const formatearCalendario = (fecha)=>{
    let day = fecha.getDate()
    let month = fecha.getMonth() + 1
    let year = fecha.getFullYear()
    if (day < 10) {
        day = `0${day}`
    }

    if (month < 10) {
        return `${day}/0${month}/${year}`
    } else {
        return `${day}/${month}/${year}`
    }

}