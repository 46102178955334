import SubSeccion from "./SubSeccion";
import { useState } from "react";
import SubSeccionFormulario from "./SubSeccionFormulario";
export const ListaSubSecciones = ({ descripcion, subsecciones, listaSecciones, visualizarReciboSubseccion=false}) => {

 const [tareas, setTareas] = useState(subsecciones);

  const agregarTarea = tarea => {

      tarea.nombre = tarea.nombre?.trim();
      let encontrarSeccion = listaSecciones.find(seccion => seccion.descripcion === descripcion)
      setTareas([...tareas, tarea])
      encontrarSeccion.subsecciones.push(tarea)

  }

  const agregarDatosTarea = (tarea,Monto, Descripcion) => {
    tarea.descripcion=Descripcion
    tarea.monto=Monto
  }
  const eliminarTarea = (nombreSubseccion,nombreSeccion) => {
    const tareasActualizadas = tareas.filter(tarea => tarea.nombre !== nombreSubseccion);
    let encontrarSeccion = listaSecciones.find(seccion => seccion.descripcion === nombreSeccion);
    encontrarSeccion.subsecciones = tareasActualizadas
    setTareas(tareasActualizadas);  
  }

  const completarTarea = id => {
    const tareasActualizadas = tareas.map(tarea => {
      if (tarea.ID_Subseccion === id) {
        tarea.completada = !tarea.completada;
      }
      return tarea;
    });
    setTareas(tareasActualizadas);
  }


    return (
        <>
         {
          !visualizarReciboSubseccion &&  <SubSeccionFormulario agregarTarea={agregarTarea}/>
        }
        <div className='tareas-lista-contenedor'>
          {
            tareas.map((tarea) =>
              <SubSeccion {...tarea} key={tarea.id} tarea={tarea} tareas={tareas} nombreSeccion={descripcion} agregarTarea={agregarTarea} eliminarTarea={eliminarTarea} completarTarea={completarTarea} agregarDatosTarea={agregarDatosTarea} visualizarReciboSubseccion={visualizarReciboSubseccion}/>
            )
          }
        </div> 
     
      </>
    )
}
