import { useReducer, useState } from "react"
import { api } from "../api/api"
import { types } from "../types/types"
import { AuthContext } from "./AuthContext"
import { authReducer } from "./authReducer"




const init = () => {
  const user = JSON.parse(localStorage.getItem('user'))

  return {
    logged: !!user,
    user: user
  }
}

export const AuthProvider = ({ children }) => {

  const [authState, dispatch] = useReducer(authReducer, {}, init)
  const login = async (username = '', password = '') => {
    try {

      const user = {
        username: username,
        password: password,
      };


      const res = await api.post("login", user);

      const action = {
        type: types.login,
        payload: user,
      };

      if (res.data?.status === 200) {
        
        if (res.data.rol === 'super-admin') {
          localStorage.setItem('user', JSON.stringify({username:user.username}));
          dispatch(action);
          return {
            validar: true,
            rol: 'super-admin',
            mensaje: res.data.mensaje
          }; 
        }
        if (res.data.rol === 'admin') {
          localStorage.setItem('user', JSON.stringify({username:user.username}));
          dispatch(action);
          return {
            validar: true,
            rol: 'admin',
            mensaje: res.data.mensaje
          };
        } 
      }
      if (res.data?.status === 400) {

        return {
          validar: false,
          mensaje: res.data.mensaje
        };
      }  
  
    } catch (error) {

      return {
        validar: false,
        mensaje: 'Error servidor'
      }; 
    }
  };




  const logout = () => {
    localStorage.removeItem('user')
    const action = {
      type: types.logout,
    }
    dispatch(action)
  }

  return (
    <AuthContext.Provider value={{
      ...authState,
      login: login,
      logout: logout,

    }}>
      {children}
    </AuthContext.Provider>
  )
}
