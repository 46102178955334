
export const doc = [
    { id: 'D', name: 'D.N.I.', adress: 'dni' },
    { id: 'CE', name: 'Carnet de Extranjería', adress: 'ce' },
    { id: 'PS', name: 'Nro. Pasaporte', adress: 'passport' }
]


export const meses = [
    { id: 1, mes: 'Enero' },
    { id: 2, mes: 'Febrero' },
    { id: 3, mes: 'Marzo' },
    { id: 4, mes: 'Abril' },
    { id: 5, mes: 'Mayo' },
    { id: 6, mes: 'Junio' },
    { id: 7, mes: 'Julio' },
    { id: 8, mes: 'Agosto' },
    { id: 9, mes: 'Septiembre' },
    { id: 10, mes: 'Octubre' },
    { id: 11, mes: 'Noviembre' },
    { id: 12, mes: 'Diciembre' },

]

/* export const years = [2021, 2022, 2023]
 */
export const getYears = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2022;
    const years = [];
    for (let i = startYear; i <= currentYear; i++) {
        years.push(i);
    }
    return years
}

export const tipos = [
    { id: 1, tipo: 'Departamento' },
    { id: 2, tipo: 'Estacionamiento' },
    { id: 3, tipo: 'Depósito' }
]
