
import { useForm } from '../../hooks/useForm';
import { api } from '../../api/api';

import { AuthContext } from '../../context/AuthContext';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export const Registro = () => {

    const { logout } = useContext(AuthContext)
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')

    const { username, password, onInputChange } = useForm({
        username: '',
        password: '',
    })

    const [baseDatos, setBaseDatos] = useState('')
    const [disabled, setDisabled] = useState(true)

    const onLogout = () => {
        logout()
        navigate('/videofinca', {
            replace: true
        })
    }

    const onInputChangeBaseDatos = (event) => {
        const { value } = event.target;
        const regex = /[^a-zA-Z]/g;
        if (regex.test(value)) {
            // Si el valor contiene algún caracter que no sea una letra exceptuando la "ñ", se muestra un mensaje de error o se toma la acción correspondiente.
            setBaseDatos(value);
            setShowAlert(true);
            setMessage("La base de datos admite solo letras, exceptuando la ñ ");
            setDisabled(true)
        } else {
            // Si el valor es válido, se actualiza el estado con el nuevo valor.
            setBaseDatos(value);
            setMessage('');
            setShowAlert(false);
            setDisabled(false)
        }
    };


    const handlesubmit = async (e) => {
        e.preventDefault()

        if (baseDatos == '') {
            setShowAlert(true);
            setMessage('Llenar el campo base de datos');
        } else {
            try {
                const data_POST = {
                    "username": username,
                    "password": password,
                    "base_datos": baseDatos.toLowerCase(),

                }

                const res = await api.post("registroadmin", data_POST)

                if (res.data.status === 200) {
                    setMessage(res.data.mensaje)
                    setShowAlert(true)
    
                }
                if (res.data.status === 400) {
                    setMessage(res.data.mensaje)
                    setShowAlert(true)
                } 
            } catch (error) {
                setMessage('Error en el servidor')
                setShowAlert(true)
            }
        }




    }

    return (
        <>
            <div className='container-fluid' >
                <div className='row'>
                    <div className='col-3 mt-4'>
                        <div className='d-flex justify-content-center'>
                            <button type="button" className="btn btn-primary boton mt-2" onClick={onLogout}>
                                Regresar
                            </button>

                        </div>
                    </div>
                    <div className='col-6'>
                        <form className="form-propietarios pb-0" onSubmit={handlesubmit}>
                            {
                                showAlert && (
                                    <div className="alert alert-success alert-dismissible fade show mt-3 text-center" role="alert">
                                        <strong>{message}</strong>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )
                            }

                            <div className="form-group row align-items-center justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                </svg>
                            </div>
                            <div className="form-group row">

                                <label htmlFor="inputEmail3" className="col-4 col-form-label">Usuario:</label>
                                <input type='text'
                                    className='form-control col-8'
                                    autoComplete="off"
                                    placeholder="Usuario..."
                                    name={"username"}
                                    value={username}
                                    onChange={onInputChange}
                                />
                            </div>

                            <div className="form-group row">

                                <label htmlFor="inputEmail3" className="col-4 col-form-label">Contraseña:</label>
                                <input type='password'
                                    className='form-control col-8'
                                    autoComplete="off"
                                    placeholder="Contraseña..."
                                    name={"password"}
                                    value={password}
                                    onChange={onInputChange}
                                />
                            </div>



                            <div className="form-group row">

                                <label htmlFor="inputEmail3" className="col-4 col-form-label">Nombre Base de Datos:</label>
                                <input type='text'
                                    className='form-control col-8'
                                    autoComplete="off"
                                    placeholder="Nombre Base de Datos..."
                                    name={"baseDatos"}
                                    value={baseDatos}
                                    onChange={onInputChangeBaseDatos}
                                />
                            </div>

                            <div className='contenedor-btn-guardar' onClick={handlesubmit}>
                                <button className='btn-guardar' disabled={disabled}>Registrar</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
