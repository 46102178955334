import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { AuthContext } from '../../context/AuthContext';

import { Boton } from '../../componentes/Boton';
import { api } from '../../api/api';

import { doc } from '../../datos/datosSelectores';

import '../../App.css';
import '../../estilos/FormPropietario.css';
import '../../estilos/TipoDoc.css';



export const UpdatePropietario = () => {

    const location = useLocation();
    const propietarioRecuperado = location.state.pro;
    const listaFincas = location.state.listafincas


    const { user } = useContext(AuthContext)

    const {
        finca_id,
        idpropiedad,
        idpropietarios,
        nombres_y_apellidos,
        tipodocumento,
        nro_documento,
        correo,
        telefono,
        numero_departamento,
        numero_estacionamiento,
        numero_deposito,
        porcentaje_participacion,
        num_cuenta,
        cci,
        cuenta_titular
       
    } = propietarioRecuperado;

    const [nombres, setNombres] = useState(nombres_y_apellidos)
    const [listatdoc, setListatdoc] = useState(doc)
    const [tdocSelect, setTdocSelect] = useState(tipodocumento)
    const [ndoc, setNdoc] = useState(nro_documento)
    const [correoProp, setCorreoProp] = useState(correo)
    const [ncel, setNcel] = useState(telefono)
    const [dep, setDep] = useState(numero_departamento)
    const [estacionamiento, setEstacionamiento] = useState(numero_estacionamiento)
    const [deposito, setDeposito] = useState(numero_deposito)
    const [part, setPart] = useState(porcentaje_participacion)
    const [fincaSelect, setFincaSelect] = useState(finca_id)
    const [n_cuenta, setN_cuenta] = useState(num_cuenta)
    const [Cci, setCci] = useState(cci)
    const [titular, setTitular] = useState(cuenta_titular)

    const [data, setData] = useState({})
    const obtenerLabelDelTipoDocumentoSeleccionado = doc.find(d => d.id === tipodocumento)?.name 
    const obtenerLabelDeLaFincaSeleccionada = listaFincas.find(f => f.id === finca_id)?.nombre  
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')
    const [error, setError] = useState('warning')
    const [inputError, setInputError] = useState(false)
    const [casosInputError, setCasosInputError] = useState('')

    function handlesubmit(e) {
        e.preventDefault()
    }

    function updatePropietario() {

        const data_PUT = {
            "user": user.username,
            "idpropiedad": idpropiedad,
            "idpropietarios": idpropietarios,
            "finca_id": fincaSelect,
            "nombres_y_apellidos": nombres?.trim(),
            "tipodocumento": tdocSelect,
            "nro_documento": ndoc?.trim(),
            "correo": correoProp?.trim(),
            "telefono": ncel?.trim(),
            "numero_departamento": dep?.trim(),
            "numero_estacionamiento": estacionamiento?.trim(),
            "numero_deposito": deposito?.trim(),
            "part": part?.trim(),
            "num_cuenta": n_cuenta?.trim(),
            "cci": Cci?.trim(),
            "cuenta_titular": titular?.trim(),
        }

        try {
            api.put("propietario", data_PUT).then(
                res => {
                    setData(res.data)

                    switch (res.data.input_error) {
                        case 'departamento':
                            setInputError(res.data.error)
                            setCasosInputError('departamento')
                            break;
                        case 'estacionamiento':
                            setInputError(res.data.error)
                            setCasosInputError('estacionamiento')
                            break;
                        case 'deposito':
                            setInputError(res.data.error)
                            setCasosInputError('deposito')
                            break;
                        case 'departamento y deposito':
                            setInputError(res.data.error)
                            setCasosInputError('departamento y deposito')
                            break;
                        case 'estacionamiento y departameto':
                            setInputError(res.data.error)
                            setCasosInputError('estacionamiento y departameto')
                            break;
                        case 'estacionamiento y deposito':
                            setInputError(res.data.error)
                            setCasosInputError('estacionamiento y deposito')
                            break;
                        case 'todos':
                            setInputError(res.data.error)
                            setCasosInputError('todos')
                            break;
                        default:
                            break;
                    }

                }
            )

        }
        catch (error) {
            alert("Hubo error en el servidor")
        }

    }

    useEffect(() => {

        if (data.status === 200) {

            setShowAlert(true)
            setMessage(data.mensaje)
            setError('primary')
            setInputError(false)

        }
        if (data.status === 201) {
            setShowAlert(true)
            setMessage(data.mensaje)
            setError('warning')


        }
        if (data.status === 400) {
            setShowAlert(true)
            setMessage(data.mensaje)
            setError('warning')
        }
        if (data.status === 500) {
            setShowAlert(true)
            setMessage(data.mensaje)
            setError('warning')
        }

    }, [data])

    

    return (
        <>
            <div className='container-fluid' >
                <div className='row'>
                    <div className='col-3 mt-4'>
                        <div className='d-flex justify-content-center'>
                            <Boton ruta={'propiedades'} accion={'Regresar'} />
                        </div>
                    </div>

                    <div className='col-9'>
                        <form className="form-propietarios pb-0" onSubmit={handlesubmit}>
                            <div className="form-group row">
                                {
                                    showAlert && (
                                        <div className={`alert alert-${error} alert-dismissible fade show`} role="alert">
                                            <strong>{message}</strong>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    )
                                }

                            </div>


                            <div className="form-group row  align-items-center">
                                <label htmlFor="exampleFormControlSelect1" className="col-3 col-form-label">Finca:</label>
                                <div className='input-select col-4' >
                                    <Select d
                                        onChange={
                                            (seleccion) => {
                                                setFincaSelect(seleccion.value)
                                            }
                                        }
                                        options={listaFincas?.map(sup => ({ label: sup.nombre, value: sup.id }))}
                                        defaultValue={{ label: obtenerLabelDeLaFincaSeleccionada, value: fincaSelect }}

                                    /></div>

                            </div>

                            <div className="form-group row  align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Departamento :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Departamento "
                                    name={"dep"}
                                    value={dep}
                                    onChange={e => setDep(e.target.value)}
                                    style={
                                        (inputError & (casosInputError === 'departamento' || casosInputError === 'departamento y deposito' || casosInputError === 'estacionamiento y departameto' || casosInputError === 'todos')) ? {
                                            borderColor: 'red'
                                        } : {}
                                    }
                                />
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Estacionamiento:</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Estacionamiento "
                                    style={
                                        (inputError & (casosInputError === 'estacionamiento' || casosInputError === 'estacionamiento y departameto' || casosInputError === 'estacionamiento y deposito' || casosInputError === 'todos')) ? {
                                            borderColor: 'red'
                                        } : {}
                                    }
                                    name={"estacionamiento"}
                                    value={estacionamiento}
                                    onChange={e => setEstacionamiento(e.target.value)} />
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Nro. de Depósito:</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Depósito "
                                    style={
                                        (inputError & (casosInputError === 'deposito' || casosInputError === 'departamento y deposito' || casosInputError === 'estacionamiento y deposito' || casosInputError === 'todos')) ? {
                                            borderColor: 'red'
                                        } : {}
                                    }
                                    name={"Numero_deposito"}
                                    value={deposito}
                                    onChange={e => setDeposito(e.target.value)} />
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Participación (%):</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Participación(%) "
                                    name={"part"}
                                    value={part}
                                    onChange={e => setPart(e.target.value)} />
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="exampleFormControlSelect1" className="col-3 col-form-label">Tipo de Documento:</label>
                                <div className='input-select col-4'>
                                    <Select
                                        onChange={
                                            (seleccion) => {
                                                setTdocSelect(seleccion.value)
                                            }
                                        }
                                        options={listatdoc.map(sup => ({ label: sup.name, value: sup.id }.disabled))}
                                        defaultValue={{ label: obtenerLabelDelTipoDocumentoSeleccionado, value: tdocSelect }}
                                    />
                                </div>
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Nro. de Documento :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Nro. de Documento "
                                    name={"ndoc"}
                                    value={ndoc}
                                    onChange={e => setNdoc(e.target.value)} />
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Nombres y Apellidos:</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Nombre y Apellido "
                                    name={"nombres"}
                                    value={nombres}
                                    onChange={e => {
                                        setNombres(e.target.value)
                                    }
                                    } />
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Correo Electrónico:</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Correo Electrónico "
                                    name={"correoProp"}
                                    value={correoProp}
                                    onChange={e => setCorreoProp(e.target.value)} />
                            </div>

                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Nro. de Celular :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    placeholder="Nro. de Celular "
                                    name={"ncel"}
                                    value={ncel}
                                    onChange={e => setNcel(e.target.value)} />
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">N° Cuenta :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="N° Cuenta"
                                    name={"n_cuenta"}
                                    value={n_cuenta}
                                    onChange={e => setN_cuenta(e.target.value)} />
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">CCI :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="CCI"
                                    name={"cci"}
                                    value={Cci}
                                    onChange={e => setCci(e.target.value)} />
                            </div>
                            <div className="form-group row align-items-center">
                                <label htmlFor="inputEmail3" className="col-3 col-form-label">Titular :</label>
                                <input type='text'
                                    className='form-control col-4'
                                    autoComplete="off"
                                    placeholder="Titular"
                                    name={"titular"}
                                    value={titular}
                                    onChange={e => setTitular(e.target.value)} />
                            </div>

                        </form>
                        <div className='d-flex justify-content-center mb-2' >

                            <button type="button" className="btn btn-primary boton mt-2" onClick={()=>updatePropietario()}>
                                Actualizar
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
